enum HomeEnum {
    applyShow = "cn.applyShow",
    restaurantOperations = "cn.restaurantOperations",
    memberMarket = "cn.memberMarket",
    supplyChain = "cn.supplyChain",
    chainManage = "cn.chainManage",
    smartManagementStores = "cn.smartManagementStores",
    quicklyOnlineOperations = "cn.quicklyOnlineOperations",
    fullBusinessDisplay = "cn.fullBusinessDisplay",
    multiDimensionalLevelControl = "cn.multiDimensionalLevelControl",
    intelligentConcise = "cn.intelligentConcise",
    multiChannelIntegration = "cn.multiChannelIntegration",
    multiFormat = "cn.multiFormat",
    visualization = "cn.visualization",
    learnMore = "cn.learnMore",
    quickAccess = "cn.quickAccess",
    allSupport = "cn.allSupport",
    allChannelIntegration = "cn.allChannelIntegration",
    allBusinessFormatsSupport = "cn.allBusinessFormatsSupport",
    remoteView = "cn.remoteView",
    intelligentCateringSaas = "cn.intelligentCateringSaas",
    moreIntelligentEfficient = "cn.moreIntelligentEfficient",
    finance = "cn.finance",
    paymentConfig = "cn.paymentConfig",
    separateAccounts = "cn.separateAccounts",
    invoice = "cn.invoice",
    taxAdmin = "cn.taxAdmin",
    reportForms = "cn.reportForms",
    statementAnalysis = "cn.statementAnalysis",
    staff = "cn.staff",
    purview = "cn.purview",
    clockIn = "cn.clockIn",
    dailySettlement = "cn.dailySettlement",
    rearKitchen = "cn.rearKitchen",
    make = "cn.make",
    guQing = "cn.guQing",
    takeaway = "cn.takeaway",
    thirdDeliveryPlatform = "cn.thirdDeliveryPlatform",
    lineUp = "cn.lineUp",
    lineUpCall = "cn.lineUpCall",
    callScreen = "cn.callScreen",
    onlineBook = "cn.onlineBook",
    scanCodeOrder = "cn.scanCodeOrder",
    scanCodePayment = "cn.scanCodePayment",
    POSCashier = "cn.POSCashier",
    handheldOrder = "cn.handheldOrder",
    bookOrder = "cn.bookOrder",
    onlineOrder = "cn.onlineOrder",
    onlineOrderAccept = "cn.onlineOrderAccept",
    productVideoIntroduce = "cn.productVideoIntroduce",
    merchantCommonChoice = "cn.merchantCommonChoice",
}
export default HomeEnum;
