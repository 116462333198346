import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import PCFooter from "./PCLayouts/Footer/index";
import PCHeader from "./PCLayouts/Header/index";
import MobileHeader from "./MobileLayouts/Header";
import MobileFooter from "./MobileLayouts/Footer";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { FloatButton } from "antd";
import "./index.less";
import Loading from "@src/Basic/Loading";

const CNMain = () => {
  const { pathname, hash, state } = useLocation();
  const [isShowFixedHeader, setIsShowFixedHeader] = useState<boolean>(true);
  const contentRef = useRef<any>(null);
  const isHomeRef = useRef<boolean>(false);

  useEffect(() => {
    if (!hash) {
      contentRef.current.scrollTop = 0;
    }
  }, [pathname, hash]);

  const isHome = useMemo(() => {
    return pathname === "/home";
  }, [pathname]);

  const handleScroll = (e: any) => {
    if (isHomeRef.current) {
      setIsShowFixedHeader(!!e.target.scrollTop);
    }
  };

  useEffect(() => {
    if (isHome) {
      document.getElementById("pageContent")?.addEventListener("scroll", handleScroll);
    }
    setIsShowFixedHeader(!isHome);
    isHomeRef.current = isHome;
    return () => {
      document.getElementById("pageContent")?.removeEventListener("scroll", handleScroll);
    };
  }, [pathname, isHome]);

  return (
    <div className="pageContentWrap w-full h-full flex flex-col">
      <BrowserView>{isShowFixedHeader && <PCHeader />}</BrowserView>
      <MobileView>
        <MobileHeader />
      </MobileView>
      <div
        ref={contentRef}
        id="pageContent"
        className="overflow-x-hidden w-full"
        style={{
          minWidth: "1440PX",
        }}
      >
        {!isShowFixedHeader && (
          <div style={{ background: "#F1F4F9" }}>
            <PCHeader />
          </div>
        )}
        <Suspense
          fallback={
            <>
              <Loading />
            </>
          }
        >
          <Outlet></Outlet>
        </Suspense>
        <BrowserView>
          <PCFooter />
        </BrowserView>
        <MobileView>
          <MobileFooter />
        </MobileView>
        <FloatButton.BackTop visibilityHeight={1200} target={() => contentRef.current} />
      </div>
    </div>
  );
};
export default CNMain;
