// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";

const PCRoutes: IRoute[] = [
    {
        path: "/:lang/home",
        Component: lazy(() => import("@src/Pages/CN/PC/Home")),
    },
    {
        path: "/:lang/product/efficient",
        Component: lazy(() => import("@src/Pages/CN/PC/Efficient")),
    },
    {
        path: "/:lang/product/online-restaurant",
        Component: lazy(() => import("@src/Pages/CN/PC/OnlineRestaurant")),
    },
    {
        path: "/:lang/product/marketing",
        Component: lazy(() => import("@src/Pages/CN/PC/Marketing")),
    },
    {
        path: "/:lang/product/supply-chain",
        Component: lazy(() => import("@src/Pages/CN/PC/SupplyChain")),
    },
    {
        path: "/:lang/product/manage-decision",
        Component: lazy(() => import("@src/Pages/CN/PC/ManageDecision")),
    },
    {
        path: "/:lang/product/chain-control",
        Component: lazy(() => import("@src/Pages/CN/PC/ChainControl")),
    }
];

export const PCNoFrameworkRoutes = [
    {
        path: "/protocol",
        Component: lazy(() => import("@src/Pages/CN/PC/ProtocolAndPrivacy/Protocol")),
    },
    {
        path: "/privacy",
        Component: lazy(() => import("@src/Pages/CN/PC/ProtocolAndPrivacy/Privacy")),
    }
]

export default PCRoutes;
