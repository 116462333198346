import { LanguageContextProvider } from "@src/Basic/Language/Provider.tsx";
import { RouterProvider } from "react-router-dom";
import router from "@src/Router/index";
import { queryLanguageResource } from "./Api/Common/CommonApi";
import { useEffect, useState } from "react";
import { useStore } from "./Store";
import { IKVProps } from "./Model/IKVProps";
import LanguageLocalForageStore from "./Localforage/LanguageLocalForageStore";
import Emitter from "./Utils/Emitter";
import Loading from "./Basic/Loading";
import { i18nAddResource } from "./Basic/Language/i18n";
import GlobalConfig, { IRequestEnvEnm } from "./GlobalConfig";
import { useTranslation } from "react-i18next";
import CNEnum from "./Locales/Constants/CNEnum";

function App() {
  const { commonStore } = useStore();
  // TODO 默认 loading,每次刷新都获取多语
  const [languageLoading, setLanguageLoading] = useState(false);
  useEffect(() => {
    Emitter.on("languageLoad", (status: boolean) => {
      setLanguageLoading(status);
    });
    return () => {
      Emitter.offAll("languageLoad");
    };
  }, []);

  const getLanguageList = () => {
    let languageList: any[] = [
      {
        label: "简体中文",
        value: "zh_CN",
      },
      {
        label: "繁體中文",
        value: "zh_HK",
      },
      {
        label: "English",
        value: "en_US",
        isForeign: true,
      },
      {
        label: "Español",
        value: "es",
        isForeign: true,
      },
    ];

    if (GlobalConfig.isInternational) {
      languageList = [
        {
          label: "English",
          value: "en_US",
        },
        {
          label: "Español",
          value: "es",
        },
        {
          label: "简体中文",
          value: "zh_CN",
          isForeign: true,
        },
        {
          label: "繁體中文",
          value: "zh_HK",
          isForeign: true,
        },
      ];
    }
    commonStore.setLanguageList(languageList);
    getLanguageResource(languageList);
  };

  const getLanguageResource = (languageList: IKVProps[]) => {
    Emitter.emit("languageLoad", true);
    const params: IKVProps = {
      namespace: "OfficialWebsite",
      languageCodes: languageList.map((i) => i.value),
    };
    queryLanguageResource(params)
      .then((languageResult) => {
        if (languageResult?.code !== "000") return;
        LanguageLocalForageStore.set("language", languageResult.data || []).then(() => {
          i18nAddResource(true);
        });
        // 记录获取的时间, 用于判断是否需要重新获取
        localStorage.setItem("languageTime", Date.now().toString());
      })
      .catch(() => {
        Emitter.emit("languageLoad", false);
      });
  };

  useEffect(() => {
    if (
      GlobalConfig.currentEnv === IRequestEnvEnm.Dev_CN ||
      GlobalConfig.currentEnv === IRequestEnvEnm.Dev_International
    ) {
      // 开发环境实时获取多语
      getLanguageList();
    } else {
      // 非开发环境, 如果超过 24 小时, 重新获取多语
      const languageTime = localStorage.getItem("languageTime");
      if (languageTime) {
        const now = Date.now();
        const diff = now - parseInt(languageTime);
        if (diff > 24 * 60 * 60 * 1000) {
          getLanguageList();
        } else {
          i18nAddResource(true);
        }
      } else {
        getLanguageList();
      }
    }
  }, []);

  return (
    <div className="w-screen h-screen">
      <LanguageContextProvider>
        {languageLoading ? <Loading /> : <RouterProvider router={router} />}
      </LanguageContextProvider>
    </div>
  );
}

export default App;
