import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "./Right/assets/logo.svg";
import CNEnum from "@src/Locales/Constants/CNEnum";
import ContactContainer from "@src/Components/CNComponents/ContactContainer";
import "./index.less";
import { useNavigate } from "react-router";

const Experience = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="experienceWrap">
      <Logo
        className="mr-[10PX] cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      />
      <ContactContainer>
        <div className="experienceBtn">{t(CNEnum.experience)}</div>
      </ContactContainer>
    </div>
  );
};
export default Experience;
