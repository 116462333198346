import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { useTranslation } from "react-i18next";
import styles from "@src/Components/InternationalComponents/HomeComponents/index.module.less";
import useImagesLanguage, {
  IImagesProps,
} from "@src/Components/InternationalComponents/hooks/useImageLanuage";
import { renderImageItem } from "@src/Components/InternationalComponents/utils";
import "@src/styles/mixins.less";
import { useNavigate, useParams } from "react-router-dom";
import HoverButton from "@src/Components/InternationalComponents/HoverButton";
import { Tooltip } from "antd";
import { ReactNode, useMemo, useRef } from "react";
import _ from "lodash";
import i18n from "@src/Basic/Language/i18n";
import LanguageSwitcher from "@src/Components/LanguageSwitcher";

interface IMenuCard {
  title: string;
  PCTitleIcon?: IImagesProps;
  menu?: IMenuCard[];
  path?: string;
}
interface INav {
  navName: string;
  menuContent?: IMenuCard[];
  path?: string;
}

interface ILiItem {
  o: INav;
  handleOnMenuClick: (menuItem: { path?: string }) => void;
  renderMenuCard: (menuColumn: IMenuCard) => ReactNode;
}

const LiItem = (props: ILiItem) => {
  const { o, handleOnMenuClick, renderMenuCard } = props;
  const containerRef = useRef<any>(null);

  const showToolTip = () => {
    if (containerRef.current) {
      return containerRef.current.scrollWidth > containerRef.current.clientWidth;
    }
    return false;
  };

  const renderLi = () => {
    return (
      <li
        className="relative"
        key={o.navName}
        onClick={() => handleOnMenuClick(o)}
        ref={containerRef}
      >
        {o.navName}
        {o.menuContent && (
          <div
            className={`${styles.PCMenuContent} bg-[#706efe] text-[#fff] grid grid-cols-3 absolute z-[1000]`}
          >
            {o?.menuContent?.map((o) => {
              return renderMenuCard(o);
            })}
          </div>
        )}
      </li>
    );
  };

  return _.isEmpty(o.menuContent) && showToolTip() ? (
    <Tooltip title={o.navName} color="#fff" overlayInnerStyle={{ color: "#000" }}>
      {renderLi()}
    </Tooltip>
  ) : (
    renderLi()
  );
};

const Nav = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { home } = useImagesLanguage();
  const containerRef = useRef<any>(null);
  const { lang } = useParams();

  const menuContent: IMenuCard[] = [
    {
      title: t(InternationalEnum.forYourClients, { ns: "InternationalWebsite" }),
      PCTitleIcon: home[1],
      menu: [
        { title: t(InternationalEnum.QROrder, { ns: "InternationalWebsite" }), path: "/qr-order" },
        { title: t(InternationalEnum.reserves, { ns: "InternationalWebsite" }), path: "/reservas" },
        {
          title: t(InternationalEnum.digitalMenu, { ns: "InternationalWebsite" }),
          path: "/carta-digital",
        },
        {
          title: t(InternationalEnum.quioscos, { ns: "InternationalWebsite" }),
          path: "/quioscos",
        },
      ],
    },
    {
      title: t(InternationalEnum.integration, { ns: "InternationalWebsite" }),
      PCTitleIcon: home[2],
      menu: [
        {
          title: t(InternationalEnum.backoffice, { ns: "InternationalWebsite" }),
          path: "/back-office",
        },
        {
          title: t(InternationalEnum.TPVHotel, {
            ns: "InternationalWebsite",
          }),
          path: "/tpv",
        },
        { title: t(InternationalEnum.KDS, { ns: "InternationalWebsite" }), path: "/kds" },
        {
          title: t(InternationalEnum.RSinjightApplication, { ns: "InternationalWebsite" }),
          path: "/rs-insight-app",
        },
        // {
        //   title: t(InternationalEnum.deliveryAndTakeout, { ns: "InternationalWebsite" }),
        //   // path: "/",
        // },
      ],
    },
    {
      title: t(InternationalEnum.administrate, { ns: "InternationalWebsite" }),
      PCTitleIcon: home[3],
      menu: [
        { title: t(InternationalEnum.member, { ns: "InternationalWebsite" }), path: "/member" },
        {
          title: t(InternationalEnum.marketing, { ns: "InternationalWebsite" }),
          path: "/marketing",
        },
        // {
        //   title: t(InternationalEnum.hardware, { ns: "InternationalWebsite" }),
        //   PCTitleIcon: home[4],
        //   // path: "/",
        // },
      ],
    },
  ];

  const nav: INav[] = [
    {
      navName: t(InternationalEnum.products, { ns: "InternationalWebsite" }),
      menuContent: menuContent,
      path: "/home",
    },
    {
      navName: t(InternationalEnum.restoPara, { ns: "InternationalWebsite" }),
      path: "/resto-para",
    },
    {
      navName: t(InternationalEnum.efficiency, { ns: "InternationalWebsite" }),
      path: "/tarifas",
    },
    {
      navName: t(InternationalEnum.contact, { ns: "InternationalWebsite" }),
      path: "/contact",
    },
    {
      navName: t(InternationalEnum.about, { ns: "InternationalWebsite" }),
      path: "/sobre-nosotros",
    },
  ];

  const handleOnMenuClick = (menuItem: { path?: string }) => {
    if (!menuItem.path) return;
    navigator(`/${lang}${menuItem.path}`);
  };

  const renderMenuCard = (menuColumn: IMenuCard) => {
    return (
      <div className={`${styles.PCMenuCard}`}>
        <div className={`${styles.PCColumnTitleContent}`}>
          {menuColumn.PCTitleIcon && renderImageItem(menuColumn.PCTitleIcon)}
          <div>{menuColumn.title}</div>
        </div>

        <ul>
          {menuColumn?.menu?.map((o) => (
            <li
              className={`${styles.PCLiTitleIcon} ${o.PCTitleIcon ? styles.PCLiItemIcon : ""}`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                e.stopPropagation();
                handleOnMenuClick(o);
              }}
            >
              {o.PCTitleIcon ? (
                <>
                  <div className="flex items-center">
                    {o.PCTitleIcon && renderImageItem(o.PCTitleIcon)}
                    {o.title}
                  </div>
                  <div>&gt;</div>
                </>
              ) : (
                <>
                  <div>{o.title}</div>
                  <div>&gt;</div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <nav className={`${styles.PCNav} flex-wrap bg-[#f5f7fa] relative`}>
        <div className={`${styles.PCContainer}`}>
          <div className={styles.PCImgLogo} onClick={() => handleOnMenuClick({ path: "/home" })}>
            {renderImageItem(home[0])}
          </div>
          <ul className={`${styles.PCMenuUl} items-center list-none text-[#18191f] gap-1`}>
            {nav.map((o) => (
              <LiItem
                o={o}
                renderMenuCard={renderMenuCard}
                handleOnMenuClick={handleOnMenuClick}
                key={o.path}
              />
            ))}
          </ul>
          <div className={`${styles.PCNavRight}`}>
            <div className={`${styles.PCLanguageSwitch}`}>
              <LanguageSwitcher />
            </div>
            <HoverButton
              text={t(InternationalEnum.applyLinkBtn, { ns: "InternationalWebsite" })}
              linkTo="/contact"
              style={{ marginLeft: "48px" }}
            />
          </div>
          {/* <div className={`${styles.PCNavButton}`}>
            <HoverButton
              text={t(InternationalEnum.boLinkBtn, { ns: "InternationalWebsite" })}
              linkTo="/"
            />
            <HoverButton
              text={t(InternationalEnum.applyLinkBtn, { ns: "InternationalWebsite" })}
              linkTo="/contact"
              style={{ marginLeft: "48px" }}
            />
          </div> */}
        </div>
      </nav>
    </>
  );
};

export default Nav;
