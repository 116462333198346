import "@src/Components/InternationalLayouts/Main.less";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { useTranslation } from "react-i18next";
import { ReactNode, useMemo } from "react";
import LanguageSwitcher from "@src/Components/LanguageSwitcher";
import useImagesLanguage, {
  IImagesProps,
} from "@src/Components/InternationalComponents/hooks/useImageLanuage";
import { renderImageItem } from "@src/Components/InternationalComponents/utils";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "@src/Basic/Language/i18n";

interface IMenuCard {
  title?: string | ReactNode;
  PCTitleIcon?: IImagesProps;
  menu?: IMenuCard[];
  path?: string;
  env?: string;
}

const PCFooter = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { home } = useImagesLanguage();

  const { lang } = useParams();

  const linkIcon: IImagesProps[] = [home[34], home[35], home[36], home[37]];
  const footerContent: IMenuCard[] = [
    {
      PCTitleIcon: home[33],
      menu: [
        { title: t(InternationalEnum.footerColumn1IntroTitle, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1IntroDesc1, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1IntroDesc2, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1PhoneTtile, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1Phone1, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1Phone2, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1Phone3, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1Email, { ns: "InternationalWebsite" }) },
        { title: t(InternationalEnum.footerColumn1CopyRight, { ns: "InternationalWebsite" }) },
        {
          title: t(InternationalEnum.footerColumn1AllRightsReserved, {
            ns: "InternationalWebsite",
          }),
        },
      ],
    },
    {
      title: t(InternationalEnum.products, { ns: "InternationalWebsite" }),
      menu: [
        {
          title: t(InternationalEnum.QROrder, { ns: "InternationalWebsite" }),
          path: "/qr-order",
        },
        {
          title: t(InternationalEnum.TPVHotel, {
            ns: "InternationalWebsite",
          }),
          path: "/tpv",
        },
        {
          title: t(InternationalEnum.reserves, {
            ns: "InternationalWebsite",
          }),
          path: "/reservas",
        },
        {
          title: t(InternationalEnum.RSinjightApplication, { ns: "InternationalWebsite" }),
          path: "/rs-insight-app",
        },
        {
          title: t(InternationalEnum.digitalMenu, { ns: "InternationalWebsite" }),
          path: "/carta-digital",
        },
        {
          title: t(InternationalEnum.deliveryAndTakeout, { ns: "InternationalWebsite" }),
          // path: "/",
        },
        {
          title: t(InternationalEnum.quioscos, { ns: "InternationalWebsite" }),
          path: "/quioscos",
        },
        {
          title: t(InternationalEnum.member, { ns: "InternationalWebsite" }),
          path: "/member",
        },
        { title: t(InternationalEnum.KDS, { ns: "InternationalWebsite" }), path: "/kds" },
        {
          title: t(InternationalEnum.marketing, { ns: "InternationalWebsite" }),
          path: "/marketing",
        },
        {
          title: t(InternationalEnum.backoffice, { ns: "InternationalWebsite" }),
          path: "/back-office",
        },
      ],
    },
    {
      title: t(InternationalEnum.footerColumnsTitle, { ns: "InternationalWebsite" }),
      menu: [
        {
          title: t(InternationalEnum.footerPrivacyPolicy, { ns: "InternationalWebsite" }),
          path: "/es-private",
        },
        {
          title: t(InternationalEnum.footerlegalNotice, { ns: "InternationalWebsite" }),
          path: "/es-legal-notice",
        },
        {
          title: t(InternationalEnum.cookiePolicy, { ns: "InternationalWebsite" }),
          path: "/es-cookie",
        },
      ],
    },
    // {
    //   title: (
    //     <div className="PCLanguageSwitch">
    //       <LanguageSwitcher />
    //     </div>
    //   ),
    // },
  ];

  const handleOnMenuClick = (menuItem: { path?: string }) => {
    const env = i18n.language;
    if (
      menuItem.path &&
      ["/es-legal-notice", "/es-cookie", "/es-private"].includes(menuItem?.path)
    ) {
      const envPath = env === "es" ? menuItem.path : menuItem.path.replace("es", "en");
      navigator(`/${lang}${envPath}`);
    } else {
      navigator(`/${lang}${menuItem.path}`);
    }
  };

  const renderContent = (menu: IMenuCard[] = [], index: number) => {
    if (index === 0) {
      const [
        { title: introTitle },
        { title: introDesc1 },
        { title: introDesc2 },
        { title: phoneTitle },
        { title: phone1 },
        { title: phone2 },
        { title: phone3 },
        { title: email },
        { title: copyright },
        { title: reserved },
      ] = menu;

      return (
        <div className="PCColumn1Container">
          <div className="PCSection1 PCBlock">
            <h4>{introTitle}</h4>
            <p>{introDesc1}</p>
            <p>{introDesc2}</p>
            <p>{}</p>
          </div>
          <div className="PCSection2 PCBlock">
            <p>{phoneTitle}</p>
            <p>{phone1}</p>
            <p>{phone2}</p>
            <p>{phone3}</p>
          </div>
          {/* <div className="PCSpacer"></div> */}
          <div className="PCSection3 PCBlock">
            <p>{email}</p>
          </div>
          {/* <div className="PCSpacer"></div> */}
          <div className="PCSection4 PCBlock">
            <p>{copyright}</p>
            <p>{reserved}</p>
          </div>
        </div>
      );
    }
    return (
      <ul className={`PCMenuItem`}>
        {menu?.map((o, index) => {
          return (
            <li className={`PCMenuLi`} onClick={() => handleOnMenuClick(o)} key={index}>
              {o.PCTitleIcon && renderImageItem(o.PCTitleIcon)}
              {o.title && <div>{o.title}</div>}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderMenuCard = (PCMenuCard: IMenuCard, index: number) => {
    return (
      <div className={`PCMenuCard ${index === 1 ? "PCTwoColumn" : ""} GridColumn${index + 1}`}>
        <div className={`PCTitleContent`}>
          {PCMenuCard.PCTitleIcon && renderImageItem(PCMenuCard.PCTitleIcon)}
          {PCMenuCard.title && <div className={`PCTitle`}>{PCMenuCard.title}</div>}
        </div>
        {renderContent(PCMenuCard.menu, index)}
      </div>
    );
  };

  return (
    <>
      <div className="PCFooter">
        <div className="PCFooterContainer">
          {footerContent?.map((o, index) => {
            return renderMenuCard(o, index);
          })}
          {/* <div className="PCFooterLink">
            {linkIcon?.map((o) => {
              return <div className="PCLinkItem">{renderItem(o)}</div>;
            })}
          </div> */}
        </div>
      </div>
      {/* <div className={`PCFooter2Logo`}>
        <div className={`PCFooter2ImgContainer`}>{renderImageItem(home[32])}</div>
      </div> */}
      <div></div>
    </>
  );
};
export default PCFooter;
