import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import PCFooter from "./PCLayouts/Footer";
import PCHeader from "./PCLayouts/Header";
import MobileHeader from "./MobileLayouts/Header";
import MobileFooter from "./MobileLayouts/Footer";
import { Suspense, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Button } from "antd";
import "./Main.less";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { t } from "i18next";
import Loading from "@src/Basic/Loading";
import i18n from "@src/Basic/Language/i18n";

const InternationalMain = () => {
  const { pathname, hash } = useLocation();
  const navigator = useNavigate();
  const { lang } = useParams();

  const [cookies, setCookie] = useCookies(["acceptCookie"]);

  const [showCookieSheet, setShowCookieSheet] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const contentRef = useRef<any>(null);

  useEffect(() => {
    contentRef.current.scrollTop = 0;
    console.log(73293, pathname);
  }, [pathname]);

  useEffect(() => {
    if (![1, 2].includes(cookies?.acceptCookie)) {
      setShowCookieSheet(true);
    }
  }, [cookies]);

  useEffect(() => {
    const getHeaderHeight = () => {
      const header = document.querySelector<HTMLElement>(".PCHeader");
      if (header) {
        setHeaderHeight(header?.offsetHeight);
      }
    };

    getHeaderHeight();

    window.addEventListener("resize", getHeaderHeight);

    return () => {
      window.removeEventListener("resize", getHeaderHeight);
    };
  }, []);

  const handleSetCookie = (val: number) => {
    console.log(1234, val);
    const date = new Date();
    // 一个月(毫秒)
    date.setTime(date.getTime() + 30 * 24 * 3600 * 1000);
    setCookie("acceptCookie", val, {
      expires: date,
    });
    setShowCookieSheet(false);
  };

  const handleOnMenuClick = () => {
    const env = i18n.language;
    const menuPath = "/es-private";
    const envPath = env === "es" ? menuPath : menuPath.replace("es", "en");
    navigator(`/${lang}${envPath}`);
  };

  const renderCookieSheet = () => {
    return (
      <div className="fixed bottom-0 w-full PCCookies z-[10]">
        <div className="PCookiesContainer">
          <div className="PCTextContent">
            <p>
              <span>{t(InternationalEnum.cookieTips1, { ns: "InternationalWebsite" })}</span>
              <span onClick={handleOnMenuClick} className="PCPrivateLink">
                {t(InternationalEnum.cookieTips1SpanLink, { ns: "InternationalWebsite" })}
              </span>
            </p>
            <p>{t(InternationalEnum.cookieTips2, { ns: "InternationalWebsite" })}</p>
          </div>
          <div className="flex items-center justify-end PCCookieButton">
            <Button
              onClick={() => {
                handleSetCookie(1);
              }}
              type="primary"
            >
              {t(InternationalEnum.cookieBtnAccept, { ns: "InternationalWebsite" })}
            </Button>
            <Button
              onClick={() => {
                handleSetCookie(2);
              }}
              type="primary"
            >
              {t(InternationalEnum.cookieBtnReject, { ns: "InternationalWebsite" })}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="international-main w-full h-full">
      <BrowserView className="PCHeader">
        <PCHeader />
      </BrowserView>
      <MobileView>
        <MobileHeader />
      </MobileView>
      <div
        style={{ height: `calc(100% - ${headerHeight}px)` }}
        // style={{ marginTop: headerHeight - 50 }}
        className="overflow-x-hidden w-full PCContent"
        ref={contentRef}
      >
        <Suspense
          fallback={
            <>
              <Loading />
            </>
          }
        >
          <Outlet></Outlet>
        </Suspense>
        <BrowserView>
          <PCFooter />
        </BrowserView>
        <MobileView>
          <MobileFooter />
        </MobileView>
      </div>

      {showCookieSheet && renderCookieSheet()}
    </div>
  );
};
export default InternationalMain;
