// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";

const MobileRoutes: IRoute[] = [
    {
        path: "/home",
        Component: lazy(() => import("@src/Pages/CN/Mobile/Home")),
    },
];

export default MobileRoutes;
