import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import InternationalMain from "./InternationalLayouts/Main";
import CNMain from "./CNLayouts/Main";
import { Switch } from "antd";
import GlobalConfig from "@src/GlobalConfig";
import { LanguageContext } from "@src/Basic/Language/Provider";
import i18n from "@src/Basic/Language/i18n";
import _ from "lodash";
import { useStore } from "@src/Store";

const Framework = () => {
  const navigator = useNavigate();
  const { setLanguage, language } = useContext(LanguageContext);
  const { pathname, state } = useLocation();
  const [isInternational, setIsInternational] = useState<boolean>(GlobalConfig.isInternational);
  const isDev = process.env.NODE_ENV === "development";
  const { lang } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  // 开发环境，根据 localStorage 设置国际化
  useEffect(() => {
    if (!isDev) return;
    setIsInternational(localStorage.getItem("isInternational") === "1");
  }, [isDev]);

  useEffect(() => {
    console.log("pathname", pathname);
    if (pathname === "/") {
      navigator(`/${language}/home`);
    } else if (lang) {
      const path = pathname.replace(`/${lang}`, "");
      const stateParams = Object.keys(state || {})?.length ? state : {};
      navigator(`/${language}${path}`, { state: stateParams });
    }
  }, [language, pathname]);

  useEffect(() => {
    console.log("language====>", language);
    if (language) {
      setLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    console.log("isInternational", isInternational);
    if (isInternational) {
      setLanguage(["en_US", "es"].includes(language) ? language : "en_US"); // 国际版默认英文
    } else {
      setLanguage(["zh_CN", "zh_HK"].includes(language) ? language : "zh_CN"); // 国内版默认简体中文
    }
  }, [isInternational]);

  return (
    <div className="w-full h-full">
      {/* <div className="flex fixed top-0 z-[999999]">
        <button
          onClick={() => {
            isInternational ? GlobalConfig.pushToCN() : GlobalConfig.pushToInternational();
          }}
        >
          switch env
        </button>
      </div> */}

      {isInternational ? <InternationalMain /> : <CNMain />}
    </div>
  );
};
export default Framework;
