import CNEnum from "@src/Locales/Constants/CNEnum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useNavList = () => {
  const { t, i18n } = useTranslation();
  const navList = useMemo(() => {
    return [
      {
        title: t(CNEnum.home),
        key: "home",
        path: "/home",
      },
      {
        title: t(CNEnum.product),
        key: "product",
        path: "/product",
        chevron: "down",
        content: [
          {
            title: t(CNEnum.efficientOperation),
            path: "/product/efficient", // 页面路径
            list: [
              { label: `KIOSK（${t(CNEnum.largeScreenOrder)}）`, anchor: "kiosk" },
              { label: `POS（ ${t(CNEnum.cashierSystem)}）`, anchor: "pos" },
              { label: `KDS（${t(CNEnum.kitchenProduceSystem)}）`, anchor: "kds" },
              { label: t(CNEnum.handheldOrder), anchor: "handleOrder" },
              { label: t(CNEnum.electronicMenu), anchor: "electronicMenu" },
              { label: t(CNEnum.intelligentCalling), anchor: "intelligentCall" },
            ],
          },
          {
            title: t(CNEnum.onlineSmartRestaurant),
            path: "/product/online-restaurant", // 页面路径
            list: [
              { label: t(CNEnum.h5AndApplet), anchor: "onlineSystem" },
              { label: t(CNEnum.aggregateTakeout), anchor: "takeaway" },
              { label: t(CNEnum.onLinePayment), anchor: "onlinePayment" },
              { label: t(CNEnum.electronicInvoice), anchor: "invoiceTax", type: "invoice" },
              { label: t(CNEnum.taxAdmin), anchor: "invoiceTax", type: "tax" },
              { label: t(CNEnum.predetermine), anchor: "preBook" },
              { label: t(CNEnum.intelligentQueue), anchor: "lineUp" },
              { label: t(CNEnum.evaluateInteraction), anchor: "evaluate" },
              { label: t(CNEnum.businessPlatformTicket), anchor: "businessTicket" },
            ],
          },
          {
            title: t(CNEnum.smartMarketing),
            path: "/product/marketing", // 页面路径
            list: [
              { label: t(CNEnum.marketingActivities), anchor: "activities" },
              { label: t(CNEnum.memberManage), anchor: "memberManage" },
              { label: t(CNEnum.intelligentStoredValue), anchor: "intelligentStored" },
              { label: t(CNEnum.groupBuying), anchor: "gruopBuy" },
              { label: t(CNEnum.ticket), anchor: "ticket" },
              { label: t(CNEnum.memberAnalysis), anchor: "memberAnalysis" },
            ],
          },
          {
            title: t(CNEnum.supplyChain),
            path: "/product/supply-chain", // 页面路径
            list: [
              { label: t(CNEnum.inventory), anchor: "storeInventory" },
              { label: t(CNEnum.procure), anchor: "procure" },
              { label: t(CNEnum.cost), anchor: "costControl" },
              { label: t(CNEnum.delivery), anchor: "delivery" },
            ],
          },
          {
            title: t(CNEnum.manageAndDecision),
            path: "/product/manage-decision", // 页面路径
            list: [
              { label: t(CNEnum.numberSpeak), anchor: "numberSpeak" },
              { label: t(CNEnum.merchantManagePlatform), anchor: "manageBackend" },
              { label: t(CNEnum.efficientSupportTools), anchor: "supportTools" },
              { label: t(CNEnum.international), anchor: "international" },
              { label: t(CNEnum.masterDataManage), anchor: "numberSpeak" },
              { label: t(CNEnum.integrationCenter), anchor: "integratedCenter" },
            ],
          },
          {
            title: t(CNEnum.chainControl),
            path: "/product/chain-control", // 页面路径
            list: [
              { label: t(CNEnum.multiOrganizeControl), anchor: "efficientLinkage" },
              { label: t(CNEnum.chainCoordination), anchor: "chainCoordination" },
              { label: t(CNEnum.flexibleOperation), anchor: "quickExpansion" },
              { label: t(CNEnum.efficientManage), anchor: "efficientManage" },
            ],
          },
        ],
      },
      {
        title: t(CNEnum.industry),
        key: "industry",
        path: "/industry",
      },
      {
        title: t(CNEnum.customer),
        key: "custom",
        path: "/custom",
      },
      {
        title: t(CNEnum.study),
        key: "study",
        path: "/study",
      },
      {
        title: t(CNEnum.cooperativePartner),
        key: "partner",
        path: "/partner",
      },
      {
        title: t(CNEnum.companyIntroduction),
        key: "introduce",
        path: "/introduce",
      },
    ];
  }, [i18n.language]);

  return {
    navList,
  };
};

export default useNavList;
