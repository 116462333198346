import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ChevronDonw } from "@src/assets/CN/svg/chevron-donw.svg";
import { ReactComponent as ChevronUp } from "@src/assets/CN/svg/chevron-up.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useNavList from "./useNavList";
import _ from "lodash";
import i18n from "@src/Basic/Language/i18n";

const NavList = () => {
  const { pathname } = useLocation();
  const { navList } = useNavList();
  const [currentTab, setCurrentTab] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const { lang } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  useEffect(() => {
    const key = lang ? pathname.replace(`/${lang}`, "").split("/")[1] : pathname.split("/")[2];
    setCurrentTab(_.find(data, { key })?.key);
  }, [data, pathname]);

  useEffect(() => {
    setData(navList);
  }, [navList]);

  const handleChevron = (key: string, chevron: string) => {
    const newData = data.map((item) => {
      if (item.chevron && item.key === key) {
        item.chevron = chevron;
      }
      return item;
    });
    setData(newData);
  };

  const handleNavigate = (item: any) => {
    navigate(`/${lang}${item.path}`);
  };

  const handleTabNav = (item: any) => {
    const { path, key, content } = item;
    handleChevron(key, item.chevron === "up" ? "down" : "up");
    if (!content?.length) {
      !content?.length && navigate(`/${lang || i18Language}${path}`);
      key && setCurrentTab(key);
    }
  };

  const handleNavAnchor = (path: string, anchor: string, type?: string) => {
    const search = type ? `?type=${type}` : "";
    navigate(`/${lang}${path}${search}`, { state: { anchor } });
  };

  return (
    <div className="PCNavList flex h-full">
      {data.map((item: any) => {
        return (
          <div
            className="h-full relative"
            key={item.key}
            onMouseEnter={(e) => {
              handleChevron(item.key, "up");
            }}
            onMouseLeave={(e) => {
              handleChevron(item.key, "down");
            }}
            onClick={(e) => {
              handleTabNav(item);
            }}
          >
            {item.chevron === "up" && (
              <div
                id="navContent"
                className="navWrap flex"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {item.content.map((navContent: any, index: number) => {
                  return (
                    <div className="navContent" key={index}>
                      {navContent.title && (
                        <nav
                          className="navTitle cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigate(navContent);
                            handleChevron(item.key, "down");
                          }}
                        >
                          {navContent.title}
                        </nav>
                      )}
                      {navContent.list.map((nav: any, idx: number) => {
                        return (
                          <nav
                            className="navLabel cursor-pointer "
                            key={idx}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNavAnchor(navContent.path, nav.anchor, nav.type);
                              handleChevron(item.key, "down");
                            }}
                          >
                            {nav.label}
                          </nav>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
            <div
              id={`tabTitle${item.key}`}
              className={`tabTitle h-full flex items-center cursor-pointer ${
                currentTab === item.key ? "activeTab" : ""
              }`}
            >
              <span>{item.title}</span>
              {item.chevron && (
                <ChevronDonw className={`${item.chevron === "up" ? "chevronUp" : "chevronDown"}`} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NavList;
